*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Rubik:wght@400;700&display=swap');

html {
  font-size: 100%;
}

/*16px*/

:root {
  /* colors */
  --primary-main: #F36C59;
  --primary-100: #ff4229;
  --primary-101: #6D3722;
  --primary-102: #F27462;
  --primary-103: #F2A397;
  --primary-200: #e64922;
  --primary-300: #ff9970;
  --primary-400: #ffdbcd;
  --primary-500: #f86d1c;
  --primary-600: #fd9c4c;
  --primary-700: #943215;
  --primary-800: #fae8d3;
  --primary-900: #fbb57b;
  --primary-901: #f6e9e1;
  /* grey */
  --grey-main: #545454;
  --grey-101: #DEDEE0;
  --grey-102: #A09A97;
  --grey-103: #CFC7C3;
  --grey-104: #EBE9E4;
  --grey-100: #222;
  --grey-200: #333;
  --grey-300: #888;
  --grey-400: #ccc;
  --grey-500: #e2dfdf;
  --grey-600: #cfcccc;
  --grey-700: #e0dddd;
  --grey-800: #efefef;

  --success-color: #aae2b6;
  --error-color: #f38a8a;

  /* rest of the colors */
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #c2333f;
  --green-light: #d1e7dd;
  --green-dark: #166440;

  /* fonts  */
  --headingFont: 'Rubik', sans-serif;
  --bodyFont: 'Montserrat', sans-serif;
  --smallText: 0.7em;
  /* rest of the vars */
  --backgroundColor: var(--grey-104);
  --textColor: var(--grey-100);
  --borderRadius: 0.2rem;
  --letterSpacing: 0.1rem;
  --transition: 0.3s ease-in-out all;
  --transition-1: 0.1s ease-in-out all;
  --max-width: 1120px;
  --fixed-width: 500px;
  --fluid-width: 90vw;

  /* box shadow*/
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 0 6px -1px rgba(0, 0, 0, 0.2),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

body {
  background: var(--backgroundColor);
  font-family: var(--bodyFont);
  font-weight: 400;
  line-height: 1.75;
  color: var(--textColor);
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-family: var(--headingFont);
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
}

img {
  width: 100%;
  display: block;
  object-fit: cover;
}

textarea {
  border: none;
  outline: none;
  padding: 0.5rem;
}

input[type="file"] {
  display: none;
}

.input-file-label {
  background-color: var(--grey-103);
  display: block;
  cursor: pointer;
}

input,
.select,
select {
  border: none;
  outline: none;
  margin-left: 0.5rem;
  padding: 0.5rem;
  background: transparent;
  letter-spacing: var(--letterSpacing);
  border-bottom: 1px solid var(--grey-102);
  transition: var(--transition);
}

input:focus {
  border: 1px solid var(--grey-500);
  box-shadow: var(--shadow-1);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--grey-103) inset !important;
}



.select {
  width: 15rem;
  height: 1.5rem;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-arrow-down {
  display: grid;
  place-items: center;
  width: 1.5rem;
  height: 1.5rem;
  text-align: right;
}

.select-option-container {
  position: absolute;
  top: 1.5rem;
  max-height: 50vh;
  overflow-y: scroll;
  width: 15rem;
  margin-left: -0.5rem;
  padding: 0.5rem;
  padding-right: 1.5rem;
  background-color: var(--grey-101);
}

.select-option {
  cursor: pointer;
  line-height: 2rem;
}

.select-option-display {
  display: grid;
  grid-template-columns: 1fr 1.2rem 1.2rem;
  column-gap: 0.5rem;
}

select:focus,
input:focus {
  border: none;
  /* border-bottom: 1px solid var(--grey-103); */
}

/* Page */
.min-height-center {
  min-height: calc(100vh - 14.5rem);
  display: flex;
  justify-content: center;
  padding-top: 3rem;
}

/* buttons */

.btn {
  cursor: pointer;
  color: var(--white);
  background: var(--primary-200);
  border: transparent;
  border-radius: var(--borderRadius);
  font-size: 1.2rem;
  letter-spacing: var(--letterSpacing);
  padding: 0.5rem 1.5rem;
  box-shadow: var(--shadow-1);
  transition: var(--transition);
  text-transform: capitalize;
}

.btn:hover {
  opacity: 0.7;
}

.btn-hipster {
  color: var(--primary-500);
  background: var(--primary-200);
}

.btn-hipster:hover {
  color: var(--primary-200);
  background: var(--primary-700);
}

.btn-block {
  width: 100%;
}

.btn-small {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
}

.btn:disabled {
  cursor: not-allowed;
}

/* used on login and resetPassword pages */
.btnResetPassword {
  outline: none;
  border: none;
  background: transparent;
  letter-spacing: var(--letterSpacing);
  font-size: 0.8rem;
  cursor: pointer;
  margin: 1rem 0;
  padding: 0.8rem 0;
  max-width: 10rem;
}

.btnResetPassword:hover {
  color: var(--primary-main);
}

/* alerts */
.alert {
  padding: 0.375rem 0.75rem;
  margin: 1rem auto;
  border-color: transparent;
  border-radius: var(--borderRadius);
  width: var(--fluid-width);
  max-width: var(--fixed-width);
  text-align: center;
}

.alert-danger {
  color: var(--red-dark);
  background: var(--red-light);
}

.alert-success {
  color: var(--green-dark);
  background: var(--green-light);
}

/* form */
.page-height {
  min-height: calc(100vh - 9rem);
  margin: 1rem 0;
}

.form {
  width: 100%;
  max-width: var(--fixed-width);
  border-radius: var(--borderRadius);
  padding: 1.3rem 0.8rem;
  background: var(--grey-103);
}

.form-textarea {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: var(--borderRadius);
  border: 1px solid var(--grey-200);
  height: 7rem;
}

.form-label {
  width: 100%;
  font-size: var(--smallText);
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}

.auth-options {
  font-size: 0.8rem;
  margin: 0;
  text-align: center;
}

.auth-options a {
  margin-left: 0.3rem;
  color: var(--grey-main);
}

.auth-options a:visited {
  color: var(--grey-main);
}

h2 {
  text-align: center;
}

.btn-container {
  width: 100%;
  display: grid;
  place-items: center;
  padding: 1rem 0;
}

.auth-btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

@media screen and (min-width: 550px) {
  .auth-btn {
    padding: 0.6rem 1.6rem;
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 790px) {
  .auth-options {
    font-size: 1rem;
  }

  .form {
    padding: 1.3rem;
  }
}

.info-card-container {
  padding: 1rem;
  background: var(--grey-103);
  border-radius: var(--borderRadius);
}

.info-text {
  padding: 0.5rem 0;
}

ul,
.info-card-container p {
  font-size: 0.8rem;
}

li,
.info-card-container span {
  font-size: 0.7rem;
}

.info-card-icon {
  border-radius: var(--borderRadius);
  font-size: 1.5rem;
  padding: 2rem 1rem;
  background: var(--grey-200);
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: var(--primary-main);
}

@media screen and (min-width: 550px) {
  .info-card-icon {
    font-size: 2rem;
  }
}

@media screen and (min-width: 990px) {

  ul,
  .info-card-container p {
    font-size: 1rem;
  }

  li,
  .info-card-container span {
    font-size: 0.7rem;
  }
}

::placeholder {
  font-family: inherit;
  color: var(--grey-400);
}

.form-alert {
  color: var(--red-dark);
  letter-spacing: var(--letterSpacing);
  text-transform: capitalize;
}

.btn-space {
  padding: 0.7rem 1.5rem;
  margin: 0.5rem auto;
}

/* strip */
.strip-parent {
  position: relative;
  overflow: hidden;
}

.strip {
  padding: 0 2rem;
  text-transform: uppercase;
  font-size: 0.6rem;
  opacity: 0.7;
}

.strip-featured,
.strip-free {
  position: absolute;
  top: 0.5rem;
  right: -1rem;
  transform: rotate(15deg);
  border-radius: 0 60%;
}

.strip-free {
  font-size: 0.55rem
}

@media screen and (min-width: 790px) {
  .strip {
    font-size: 0.7rem;
  }
}


@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading-container {
  display: grid;
  place-items: center;
  height: calc(100vh - 7rem);
  padding: 5rem;
}

.loading {
  width: 6rem;
  height: 6rem;
  border: 5px solid var(--primary-400);
  border-radius: 50%;
  border-top-color: var(--primary-main);
  animation: spinner 0.6s linear infinite;
}

/* .center-height {
  display: grid;
  place-items: center;
} */

.form-loading::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--borderRadius);
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  cursor: not-allowed;
}

/* navlink */
.link {
  color: var(--grey-main);
}

.link:hover {
  color: var(--primary-500);
  border-bottom: 1px solid var(--primary-500);
}

/* .active {
  color: green;
}

.active:hover {
  color: green;
} */

.footer-icon {
  color: var(--primary-901);
  transition: var(--transition);
  cursor: pointer;
  font-size: 1.3rem;
  padding: 0 0.7rem;
}

.footer-icon:hover {
  color: var(--primary-main);
  transform: translateY(-5px);
}

@media screen and (min-width: 550px) {
  .footer-icon {
    font-size: 2rem;
    padding: 0 1.2rem;
  }
}


/* buttons for pagination */
.btn-pages-container {
  display: flex;
  justify-content: center;
  margin: 2.5rem 0;
}

.btn-page {
  padding: 0.3rem 0.7rem;
  border-radius: 0;
  margin: 0 0.3rem;
}

.page-active {
  color: var(--primary-700);
  opacity: 0.9;
}

.error {
  margin: 6rem;
  font-size: 1.2rem;
  color: var(--red-dark);
  text-align: center;
}

.modal-container {
  background: rgba(50, 50, 50, 0.4);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: grid;
  place-items: center;
}

.modal-btn {
  border: none;
  display: grid;
  place-items: center;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  color: var(--grey-104);
  background: var(--primary-main);
  border-radius: var(--borderRadius);
}

.modal-btn:hover {
  opacity: 0.8;
}

.modal-submit-btn {
  width: 30%;
  height: 2.5rem;
  margin: 0 auto;
  font-size: 1rem;
  letter-spacing: var(--letterSpacing);
}